

export const cabecotes = [
{text:"Verificar comando de válvulas"},
{text: "Verificar mancais do eixo"},
{text: "Verificar trincas"},
{text: "Verificar planicidade da superfície"},
{text: "Verificar estanqueidade"},
{text: "Verificar guia de válvulas"},
{text: "Verificar sedes de válvulas e sua altura em relação à superfície"},
{text: "Verificar antecâmaras"},
{text: "Verificar roscas"},
{text: "Verificar volume da câmara de combustão"},
{text: "Verificar alojamento dos tuchos"}
]
